import React, { useCallback, useContext, useEffect } from "react";

import { useLazyQuery } from "@apollo/client";
import Footer from "@layout/footer";
import Header from "@layout/header";
import SessionController from "@layout/sessionController";
import { LayoutProps } from "@layout/types";
import { useLocation } from "@reach/router";
import { captureMessage } from "@sentry/gatsby";
import { navigate } from "gatsby";

import { ACCESS_TOKEN } from "@/account/user";
import { PaymentState } from "@/autoGeneratedGlobalTypes";
import { UrlParamKeyEnum } from "@/components/constants";
import { GET_CARROT_HASH } from "@/components/layout/Template/graphql/GET_CARROT_HASH";
import UserContext from "@/contexts/User/UserContext";
import { UtmAnalysisEventEnum, useManageUtmData } from "@/hooks/useManageUtmData";
import { useMarketingHook } from "@/marketing/marketingHook";
import { isBrowser } from "@/utils/env";

import AuthorizationModals from "../modals/authorizationModals";
import CommonModals from "../modals/commonModals";
import EmailConfirmationModals from "../modals/emailConfirmationModals";
import { FormTypeEnum } from "../modals/types";

import { CHECK_PAYMENT_STATE_PERIOOD_MS, GOOGLE_REFERER, YANDEX_REFERER } from "./constants";
import {
  checkAffiliateLink,
  checkAffiliateLinkVariables,
} from "./graphql/__generated__/checkAffiliateLink";
import { getCarrotHash } from "./graphql/__generated__/getCarrotHash";
import { getPaymentState, getPaymentStateVariables } from "./graphql/__generated__/getPaymentState";
import { getUserBalance } from "./graphql/__generated__/getUserBalance";
import { CHECK_AFFILIATE_LINK } from "./graphql/CHECK_AFFILIATE_LINK";
import { GET_PAYMENT_STATE } from "./graphql/GET_PAYMENT_STATE";
import { GET_USER_BALANCE } from "./graphql/GET_USER_BALANCE";
import { removeReferralKeyFromCookies, setReferralKeyInCookies } from "./utils";

const Template = ({ children }: LayoutProps) => {
  const {
    isUserLoggedIn,
    setIsUserLoggedIn,
    unfinishedPaymentId,
    setUnfinishedPaymentId,
    paymentFinishedCallback,
    setPaymentFinishedCallback,
    setBalance,
    setClientReferrer,
    savedCardPaymentResultPath,
    setSavedCardPaymentResultPath,
    userID,
    email,
  } = useContext(UserContext);

  const location = useLocation();
  const { marketingFirstSuccessPaymentHandler, marketingSuccessPaymentHandler } = useMarketingHook();

  const { storeUtmData, saveUtmData } = useManageUtmData();
  const [
    getPaymentStateQuery,
    { data: paymentStateData, error: paymentStateError, loading: paymentStateLoading },
  ] = useLazyQuery<getPaymentState, getPaymentStateVariables>(GET_PAYMENT_STATE);
  const [
    getUserBalanceQuery,
    { data: getUserBalanceData, error: getUserBalanceError, loading: getUserBalanceLoading },
  ] = useLazyQuery<getUserBalance>(GET_USER_BALANCE, { fetchPolicy: "network-only" });

  const [checkAffiliateLinkHandler, { data: checkAffiliateLinkData }] = useLazyQuery<
    checkAffiliateLink,
    checkAffiliateLinkVariables
  >(CHECK_AFFILIATE_LINK, { fetchPolicy: "no-cache" });

  const [getHashForCarrot, { data: carrotHashData }] = useLazyQuery<getCarrotHash>(
    GET_CARROT_HASH,
    { variables: {}, fetchPolicy: "no-cache" },
  );

  const params = new URLSearchParams(location.search);
  const refValue = params.get("ref");

  useEffect(() => {
    if (isUserLoggedIn) {
      getHashForCarrot();
    }
  }, [getHashForCarrot, isUserLoggedIn]);

  useEffect(() => {
    // todo: the presence of a token will no longer affect the display of an alert,
    //  you need to consider possible problems
    document.addEventListener("token_changed", () => {
      setIsUserLoggedIn(!!localStorage.getItem(ACCESS_TOKEN));
      if (localStorage.getItem(ACCESS_TOKEN)) {
        // setIsAuthorizationShown(false);
      }
    });

    // todo: https://3.basecamp.com/5069474/buckets/29854839/todos/7063146663
    // document.addEventListener("token_expired", () => {
    //   setAuthParamToURL(location, FormTypeEnum.Login);
    // });

    storeUtmData();
  }, []);

  useEffect(() => {
    if (refValue) {
      checkAffiliateLinkHandler({
        variables: {
          link: refValue,
        },
      });
    }
  }, [checkAffiliateLinkHandler, refValue]);

  const handlePaymentStateData = useCallback(
    (paymentData: getPaymentState, unfinishedPayment: number | null) => {
      const { state, id, amount, isFirstPayment } = paymentData.getPayment;
      if (state.toLowerCase() === PaymentState.Success.toLowerCase()) {
        marketingSuccessPaymentHandler({
          paymentId: id,
          amount: amount.amount,
          currency: amount.currency,
          userId: userID?.toString(),
          userEmail: email || "",
        });
        saveUtmData({
          eventType: UtmAnalysisEventEnum.PaymentSucceded,
          paymentID: id,
        });

        if (savedCardPaymentResultPath) {
          navigate(savedCardPaymentResultPath);
          setSavedCardPaymentResultPath("");
        }
        if (isFirstPayment) {
          captureMessage(`First-Payment ${userID}`);
          marketingFirstSuccessPaymentHandler({
            amount: amount.amount,
            userId: userID?.toString(),
            paymentId: id,
            userEmail: email || "",
          });
        }
      }
      if (state.toLowerCase() === PaymentState.Failed.toLowerCase()) {
        if (savedCardPaymentResultPath) {
          navigate(savedCardPaymentResultPath);
          setSavedCardPaymentResultPath("");
        }
      }

      if (
        ![PaymentState.Created.toLowerCase(), PaymentState.InProgress.toLowerCase()].includes(
          state.toLowerCase(),
        )
      ) {
        if (paymentFinishedCallback) {
          paymentFinishedCallback(unfinishedPayment || 0);
          setPaymentFinishedCallback(null);
        }
        setUnfinishedPaymentId(null);
        getUserBalanceQuery();
      }
    },
    [
      getUserBalanceQuery,
      paymentFinishedCallback,
      saveUtmData,
      savedCardPaymentResultPath,
      setPaymentFinishedCallback,
      setSavedCardPaymentResultPath,
      setUnfinishedPaymentId,
      userID,
      email,
    ],
  );

  useEffect(() => {
    let paymentStatePollingTimer: ReturnType<typeof setInterval> | null = null;

    if (unfinishedPaymentId) {
      paymentStatePollingTimer = setInterval(() => {
        if (unfinishedPaymentId) {
          getPaymentStateQuery({
            variables: { paymentID: unfinishedPaymentId },
            fetchPolicy: "no-cache",
          });
        }
      }, CHECK_PAYMENT_STATE_PERIOOD_MS);
    }

    return () => {
      if (paymentStatePollingTimer) {
        clearInterval(paymentStatePollingTimer);
      }
    };
  }, [getPaymentStateQuery, unfinishedPaymentId]);

  useEffect(() => {
    if (
      paymentStateData
      && !paymentStateError
      && !paymentStateLoading
      && paymentStateData.getPayment.id === unfinishedPaymentId
    ) {
      handlePaymentStateData(paymentStateData, unfinishedPaymentId);
    }
  }, [
    paymentStateData,
    paymentStateError,
    paymentStateLoading,
    unfinishedPaymentId,
    handlePaymentStateData,
  ]);

  useEffect(() => {
    if (getUserBalanceData && !getUserBalanceError && !getUserBalanceLoading) {
      setBalance(getUserBalanceData.getMyProfile.balance);
    }
  }, [getUserBalanceData, getUserBalanceError, getUserBalanceLoading, setBalance]);

  useEffect(() => {
    const { referrer } = document;
    if (referrer) {
      if (referrer.includes(GOOGLE_REFERER) || referrer.includes(YANDEX_REFERER)) {
        setClientReferrer(document.referrer);
      }
    }
  }, [setClientReferrer]);

  useEffect(() => {
    if (checkAffiliateLinkData?.checkAffiliateLink === undefined) {
      return;
    }

    if (!checkAffiliateLinkData?.checkAffiliateLink) {
      navigate(`/?${UrlParamKeyEnum.LoginFormModal}=${FormTypeEnum.ReferralKeyError}`);
      removeReferralKeyFromCookies();
    } else if (!isUserLoggedIn && refValue) {
      setReferralKeyInCookies(refValue);
      navigate(`/?${UrlParamKeyEnum.LoginFormModal}=${FormTypeEnum.Login}`);
    }
  }, [isUserLoggedIn, checkAffiliateLinkData, refValue]);

  function authenticateUserWithCarrotQuest(userId: string, userHash: string) {
    if (isBrowser()) {
      window.carrotquest?.onReady(() => {
        window.carrotquest.auth(userId, userHash);
      });
    }
  }

  useEffect(() => {
    if (carrotHashData && userID) {
      authenticateUserWithCarrotQuest(userID.toString(), carrotHashData.getCarrotHash);
    }
  }, [carrotHashData, userID]);

  useEffect(() => {
    if (window.flocktory && typeof window.flocktory.reInit === "function") {
      window.flocktory.reInit();
    }
  }, [location.href]);

  return (
    <>
      <div className="main__container">
        <Header />
        <main className="main">
          {children}
          <div id="promocode-element-container" />
        </main>
        {/* todo: devide modals and show some only for authorized users for speed optimisation */}
        <AuthorizationModals />
        <EmailConfirmationModals />
        <CommonModals />
        <SessionController />
        {/* {isBrowser() && !getCookieAccepted() && <CookieNotices />} */}
      </div>
      <Footer />
    </>
  );
};

export default Template;
