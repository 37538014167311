import { useContext } from "react";

import { Currency } from "@/autoGeneratedGlobalTypes";
import UserContext from "@/contexts/User/UserContext";

import { MARKETING_YANDEX_ID } from "./constants";
import { GtmDataLayerObject } from "./types";

export const useMarketingHook = () => {
  const { userID } = useContext(UserContext);

  const reachYandexMetricsGoal = (goalId: string, params?: object) => {
    console.log("Goal reached:", goalId);
    if (typeof window?.ym !== "undefined") {
      window.ym(MARKETING_YANDEX_ID, "reachGoal", goalId, { userID, ...params });
    }
  };

  const pushToDataLayer = (objectToPush: GtmDataLayerObject) => {
    console.log("Pushed to dataLayer:", objectToPush);

    if (typeof window?.dataLayer !== "undefined") {
      window.dataLayer.push(objectToPush);
    }
  };

  /**
   * Runs Yandex metric goal and GTM triger for login of a new user
   * @returns
   */
  const marketingUserFirstLogin = ({ userId = "0" }: { userId: string }) => {
    reachYandexMetricsGoal("User-First-Login");

    // Triggering GTM tag
    pushToDataLayer({
      event: "user-first-login",
      user_id: userId,
    });

    return true;
  };

  /**
   * Yandex metric goal for user filling email for the first time
   * Business considers filling out a questionnaire as registration now
   * @returns
   */
  const marketingUserFilledEmailForTheFirstTimeHandler = () => {
    reachYandexMetricsGoal("User-Registered");
    return true;
  };

  /**
   * Yandex metric goal for user authorization via Google
   * @returns
   */
  const marketingUserGoogleAuthorizationHandler = () => {
    reachYandexMetricsGoal("Google-Authorization");
    return true;
  };

  /**
   * Yandex metric goal for user authorization via VK
   * @returns
   */
  const marketingUserVkAuthorizationHandler = () => {
    reachYandexMetricsGoal("Vk-Authorization");
    return true;
  };

  /**
   * Yandex metric goal for button click (no matter if the user is authorized)
   * @returns
   */
  const marketingCallButtonClickHandler = () => {
    reachYandexMetricsGoal("Call-Button");
    return true;
  };

  /**
   * Yandex and Google metrics e-commerce code for expert card click
   * @returns
   */
  const marketingExpertCardClickHandler = (
    id: number,
    name: string,
    callPrice?: number,
    skills?: string[],
    expertCardPosition?: number,
    sourcePath: string = "",
  ) => {
    pushToDataLayer({ ecommerce: null }); // Clear the previous ecommerce object.

    const sourceName = sourcePath ? sourcePath.replace(/\/$|\/\?.*$|\?.*$/, "") : "Другой источник";
    pushToDataLayer({
      event: "productClick",
      ecommerce: {
        click: {
          actionField: { list: sourceName }, // Optional list property.
          // todo: add audio call and chat products when ready
          products: skills?.map((skill) =>
            ({
              id,
              name,
              price: callPrice,
              category: skill,
              position: expertCardPosition,
            })),
        },
      },
    });
    return true;
  };

  /**
   * Yandex and Google metrics e-commerce code for landing on expert page
   * @returns
   */
  const marketingOpenExpertPageHandler = (
    id: number,
    name: string,
    callPrice: number,
    skills: string[],
    sourcePath: string = "",
  ) => {
    pushToDataLayer({ ecommerce: null }); // Clear the previous ecommerce object.

    const sourceName = sourcePath ? sourcePath.replace(/\/$|\/\?.*$|\?.*$/, "") : "Другой источник";
    pushToDataLayer({
      ecommerce: {
        detail: {
          actionField: { list: sourceName },
          // todo: add audio call and chat products when ready
          products: skills.map((skill) =>
            ({
              id,
              name,
              price: callPrice,
              category: skill,
            })),
        },
      },
    });
    return true;
  };

  /**
   * Runs Yandex and Google metrics calls for successful payment
   * @returns
   */
  const marketingSuccessPaymentHandler = ({
    paymentId,
    amount,
    currency,
    name = "Пополнение баланса",
    userId = "0",
    userEmail,
  }: {
    paymentId: number | undefined;
    amount: number;
    currency: Currency;
    name?: string;
    userId?: string;
    userEmail?: string;
  }) => {
    // Yandex Metrics goal
    reachYandexMetricsGoal("Payment-Success");
    reachYandexMetricsGoal("Flocktory_successful_payment");

    // Yandex and Google metrics e-commerce code for successful payment
    pushToDataLayer({ ecommerce: null }); // Clearing the previous ecommerce object.

    pushToDataLayer({
      ecommerce: {
        currencyCode: currency,
        purchase: {
          actionField: {
            id: paymentId || "",
          },
          products: [
            {
              name,
              price: amount,
            },
          ],
        },
      },
    });

    // Triggering GTM tag (for CityAds and flocktory_successful_payment maybe other agencies)
    pushToDataLayer({
      event: "successful-payment",
      user_id: userId,
      amount,
      user_email: userEmail,
      payment_id: paymentId,
    });
    return true;
  };

  /**
   * Runs Yandex metrics goal and GTM trigger for first successful payment
   * @returns
   */
  const marketingFirstSuccessPaymentHandler = ({
    amount,
    userId = "0",
    userEmail,
    paymentId,
  }: {
    amount: number;
    userId?: string;
    userEmail?: string;
    paymentId: number | undefined;
  }) => {
    // Yandex Metrics goal
    reachYandexMetricsGoal("First-Payment");
    reachYandexMetricsGoal("Flocktory_first-Payment");

    // Triggering GTM tag
    pushToDataLayer({
      event: "first-successful-payment",
      user_id: userId,
      amount,
      user_email: userEmail,
      payment_id: paymentId,
    });

    return true;
  };

  /**
   * Yandex metric goal for "read more" button click on main page
   * @returns
   */
  const marketingMainPageReadMoreClickedHandler = () => {
    reachYandexMetricsGoal("Main-Page-Read-More-Clicked");
    return true;
  };

  /**
   * Yandex metric goal for starting a video call
   * @returns
   */
  const marketingCallStartedHandler = () => {
    reachYandexMetricsGoal("Call-Started");
    return true;
  };

  /**
   * Yandex metric goal for starting a phone call
   * @returns
   */
  const marketingPhoneCallStartedHandler = () => {
    reachYandexMetricsGoal("phone_call");
    return true;
  };

  /**
   * Yandex metric goal for connecting both expert and client to call
   * @returns
   */
  const marketingSessionStartedHandler = () => {
    reachYandexMetricsGoal("Session-Started");
    return true;
  };

  /**
   * Yandex metric goal for playing audio greeting
   * @returns
   */
  const marketingAudioGreetingPlayHandler = () => {
    reachYandexMetricsGoal("Audio-Greeting-Play");
    return true;
  };

  /**
   * Runs Yandex metric goal and GTM triger for free consultation
   * @returns
   */
  const marketingFreeConsultationHandler = () => {
    reachYandexMetricsGoal("Free_consultation");

    // Triggering GTM tag
    pushToDataLayer({
      event: "free-consultation",
    });

    return true;
  };

  /**
   * YM Main page goal
   */
  const marketingMainPage1 = () => {
    reachYandexMetricsGoal("see_experts1");
    return true;
  };

  const marketingMainPage2 = () => {
    reachYandexMetricsGoal("see_experts2");
    return true;
  };

  const marketingMainPage3 = () => {
    reachYandexMetricsGoal("see_experts3");
    return true;
  };

  const marketingMainPage4 = () => {
    reachYandexMetricsGoal("see_experts4");
    return true;
  };

  const marketingMainPage5 = () => {
    reachYandexMetricsGoal("Ask_Question");
    return true;
  };

  const marketingMainPage6 = () => {
    reachYandexMetricsGoal("get_free");
    return true;
  };

  /**
   * Yandex metric goal for continuing consultation
   * @returns
   */
  const marketingContinueConsultation = () => {
    reachYandexMetricsGoal("continue_consultation");
    return true;
  };

  /**
   * Yandex metric goal for viewing catalog
   * @returns
   */
  const marketingViewCatalog = () => {
    reachYandexMetricsGoal("view_catalog");
    return true;
  };

  /**
   * Runs Yandex metric goal and GTM triger for buttons
   * @returns
   */
  const customMarketingHandler = (metricsId: string) => {
    if (!metricsId) {
      return false;
    }

    reachYandexMetricsGoal(metricsId);

    // Triggering GTM tag
    pushToDataLayer({
      event: metricsId,
    });

    return true;
  };

  /**
   * Runs Yandex metric goal and GTM triger for gift box button click
   * @returns
   */
  const marketingGiftBoxClickHandler = () => {
    reachYandexMetricsGoal("gift_box_click");

    // Triggering GTM tag
    pushToDataLayer({
      event: "gift_box_click",
    });

    return true;
  };

  /**
   * Runs Yandex metric goal and GTM triger when gift is sent
   * @returns
   */
  const marketingGiftSentHandler = (giftId?: number) => {
    if (!giftId) {
      return false;
    }

    reachYandexMetricsGoal(`gift_sent_${giftId}`);

    // Triggering GTM tag
    pushToDataLayer({
      event: "gift_sent",
      giftId,
    });

    return true;
  };

  const marketingTrackForVideoCallHandler = () => {
    reachYandexMetricsGoal(`video_call_button_click`);

    // Triggering GTM tag
    pushToDataLayer({
      event: "video_call_button_click",
    });

    return true;
  };

  const marketingTrackForPhoneCallHandler = () => {
    reachYandexMetricsGoal(`phone_call_button_click`);

    // Triggering GTM tag
    pushToDataLayer({
      event: "phone_call_button_click",
    });

    return true;
  };

  const marketingTrackFlocktoryExchange = (clientEmail: string) => {
    reachYandexMetricsGoal(`flocktory_exchange_gift`);

    // Triggering GTM tag
    pushToDataLayer({
      event: "flocktory_exchange_gift",
      user_email: clientEmail,
    });
    return true;
  };

  const marketingTrackGet4clickExchange = (clientEmail: string) => {
    reachYandexMetricsGoal(`get4click_exchange_banner`);

    // Triggering GTM tag
    pushToDataLayer({
      event: "get_banner_4Click",
      user_email: clientEmail,
    });
    return true;
  };

  /**
   * Runs Yandex metric goal and GTM triger when promocode is applied
   * @returns
   */
  const marketingPromocodeAppliedHandler = (promocode?: string) => {
    if (!promocode) {
      return false;
    }

    reachYandexMetricsGoal("promocode");

    pushToDataLayer({
      event: "promocode",
      promocode,
    });

    return true;
  };

  const marketingOnboardingAnswers = (step?: string, answer?: string) => {
    reachYandexMetricsGoal(`onboarding`, { step, answer });

    pushToDataLayer({
      event: "onboarding_trigger_modal",
      step_onboarding: step || "",
      answer_onboarding: answer || "",
    });
    return true;
  };

  const marketingStepsForFixPaymentFromLandingPage = ({
    userId = "",
    buttonText = "",
    bannerName = "",
    fixPaymentAmount = "",
  }) => {
    reachYandexMetricsGoal(`fixed_service_5000`, { userId, buttonText, bannerName, fixPaymentAmount });

    pushToDataLayer({
      event: "fix_payment_from_landing_page",
      datalayer_user_id: userId,
      landing_button_name: buttonText,
      fix_payment_banner: bannerName,
      datalayer_amount: fixPaymentAmount,
    });

    return true;
  };

  return {
    marketingUserFirstLogin,
    marketingUserFilledEmailForTheFirstTimeHandler,
    marketingUserGoogleAuthorizationHandler,
    marketingUserVkAuthorizationHandler,
    marketingCallButtonClickHandler,
    marketingExpertCardClickHandler,
    marketingOpenExpertPageHandler,
    marketingSuccessPaymentHandler,
    marketingFirstSuccessPaymentHandler,
    marketingMainPageReadMoreClickedHandler,
    marketingCallStartedHandler,
    marketingPhoneCallStartedHandler,
    marketingSessionStartedHandler,
    marketingAudioGreetingPlayHandler,
    marketingFreeConsultationHandler,
    marketingMainPage1,
    marketingMainPage2,
    marketingMainPage3,
    marketingMainPage4,
    marketingMainPage5,
    marketingMainPage6,
    marketingContinueConsultation,
    marketingViewCatalog,
    customMarketingHandler,
    marketingGiftBoxClickHandler,
    marketingGiftSentHandler,
    marketingTrackForVideoCallHandler,
    marketingTrackForPhoneCallHandler,
    marketingTrackFlocktoryExchange,
    marketingTrackGet4clickExchange,
    marketingPromocodeAppliedHandler,
    marketingOnboardingAnswers,
    marketingStepsForFixPaymentFromLandingPage,
  };
};
