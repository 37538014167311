export const CONTACTS = {
  // wa: { title: `WhatsApp`, icon: `WhatsApp`, link: process.env.GATSBY_SUPPORT_WHATSAPP },
  tg: { title: `Telegram`, icon: `Tg`, link: process.env.GATSBY_SUPPORT_TELEGRAM },
  mail: {
    title: process.env.GATSBY_SUPPORT_EMAIL,
    icon: `Email`,
    link: process.env.GATSBY_SUPPORT_EMAIL,
  },
};

export const EXPERT_RATE_FOR_TEXTS = 70;

export const MIN_FREE_MINUTES_COUNT_FOR_IMMEDIATE_CALL = 7;

export const MIN_SESSION_DURATION = 7;

export const MINDBOX_DEVICE_ID = "mindboxDeviceUUID";
export const DEVICE_UUID = "deviceUUID";

export const COMMON_PREVIEW_IMAGE_URL = "https://storage.yandexcloud.net/all.lunaro.article.images/Frame_270989406_269b785162.png?updated_at=2024-07-01T15:46:14.862Z";
