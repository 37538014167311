/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BillingEventType {
  bonus = "bonus",
  bonus_affiliate = "bonus_affiliate",
  bonus_referral = "bonus_referral",
  chat_session = "chat_session",
  correction = "correction",
  payment = "payment",
  phone_session = "phone_session",
  product = "product",
  video_session = "video_session",
}

export enum BonusFormula {
  fixed = "fixed",
  percent = "percent",
}

export enum CardType {
  AmericanExpress = "AmericanExpress",
  Belcard = "Belcard",
  Maestro = "Maestro",
  Mastercard = "Mastercard",
  Mir = "Mir",
  Troy = "Troy",
  Unknown = "Unknown",
  VISA = "VISA",
}

export enum CashbackType {
  freeMinutes = "freeMinutes",
  money = "money",
}

export enum Currency {
  EUR = "EUR",
  RUB = "RUB",
  USD = "USD",
}

export enum ExpertDisplayStatus {
  visible_false = "visible_false",
  visible_for_admin = "visible_for_admin",
  visible_for_all = "visible_for_all",
}

export enum ExpertState {
  available = "available",
  busy = "busy",
  offline = "offline",
}

export enum Gender {
  CUSTOM = "CUSTOM",
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNKNOWN = "UNKNOWN",
}

export enum OrderEnum {
  asc = "asc",
  desc = "desc",
}

export enum PaymentButtonType {
  moneyOnly = "moneyOnly",
  moneyWithMinutes = "moneyWithMinutes",
}

export enum PaymentError {
  CommonError = "CommonError",
  DeclinedBy3DSCheck = "DeclinedBy3DSCheck",
  IncorrectPAN = "IncorrectPAN",
  IncorrectPINOrCVV = "IncorrectPINOrCVV",
  InsufficientFundsOnBalance = "InsufficientFundsOnBalance",
  NoError = "NoError",
}

export enum PaymentFormType {
  appointment = "appointment",
  profile = "profile",
  session = "session",
}

export enum PaymentLocation {
  appointment = "appointment",
  fix_from_profile = "fix_from_profile",
  profile = "profile",
  session = "session",
}

export enum PaymentProvider {
  CloudPayments = "CloudPayments",
  Other = "Other",
  SoftlinePayments = "SoftlinePayments",
  SomPayments = "SomPayments",
}

export enum PaymentState {
  Created = "Created",
  Failed = "Failed",
  InProgress = "InProgress",
  Refunded = "Refunded",
  Success = "Success",
}

export enum ScheduleStatus {
  ScheduleStatusCompleted = "ScheduleStatusCompleted",
  ScheduleStatusInProgress = "ScheduleStatusInProgress",
  ScheduleStatusScheduled = "ScheduleStatusScheduled",
  ScheduleStatusUnknown = "ScheduleStatusUnknown",
}

export enum ScheduleType {
  ScheduleTypePrivate = "ScheduleTypePrivate",
  ScheduleTypePublic = "ScheduleTypePublic",
  ScheduleTypeUnknown = "ScheduleTypeUnknown",
  ScheduleTypeWorkshop = "ScheduleTypeWorkshop",
}

export enum SessionEndReason {
  backend_reason = "backend_reason",
  billing_prohibition = "billing_prohibition",
  call_start_fail = "call_start_fail",
  ended_by_admin = "ended_by_admin",
  ended_by_client = "ended_by_client",
  ended_by_expert = "ended_by_expert",
  expert_side_call_is_not_answered_within_60_seconds = "expert_side_call_is_not_answered_within_60_seconds",
  expert_side_call_is_rejected = "expert_side_call_is_rejected",
  expert_side_call_was_not_answered_within_60_seconds = "expert_side_call_was_not_answered_within_60_seconds",
  expert_side_call_was_rejected = "expert_side_call_was_rejected",
  expert_side_destination_number_is_busy = "expert_side_destination_number_is_busy",
  expert_side_destination_number_is_unavailable = "expert_side_destination_number_is_unavailable",
  expert_side_detected_normal_call_end = "expert_side_detected_normal_call_end",
  expert_side_detected_request_disconnected_by_expert_side = "expert_side_detected_request_disconnected_by_expert_side",
  expert_side_detected_request_terminated_by_user_side = "expert_side_detected_request_terminated_by_user_side",
  expert_side_insufficient_funds = "expert_side_insufficient_funds",
  expert_side_invalid_number = "expert_side_invalid_number",
  expert_to_user_call_failed = "expert_to_user_call_failed",
  not_ended = "not_ended",
  unknown = "unknown",
  user_side_call_is_not_answered_within_60_seconds = "user_side_call_is_not_answered_within_60_seconds",
  user_side_call_is_rejected = "user_side_call_is_rejected",
  user_side_call_was_not_answered_within_60_seconds = "user_side_call_was_not_answered_within_60_seconds",
  user_side_call_was_rejected = "user_side_call_was_rejected",
  user_side_destination_number_is_busy = "user_side_destination_number_is_busy",
  user_side_destination_number_is_unavailable = "user_side_destination_number_is_unavailable",
  user_side_detected_normal_call_end = "user_side_detected_normal_call_end",
  user_side_detected_request_disconnected_by_user_side = "user_side_detected_request_disconnected_by_user_side",
  user_side_detected_request_terminated_by_expert_side = "user_side_detected_request_terminated_by_expert_side",
  user_side_insufficient_funds = "user_side_insufficient_funds",
  user_side_invalid_number = "user_side_invalid_number",
  vats_to_expert_call_failed = "vats_to_expert_call_failed",
  voice_mail_detected = "voice_mail_detected",
}

export enum SessionStatus {
  ended = "ended",
  ended_with_review = "ended_with_review",
  failed = "failed",
  in_progress = "in_progress",
  waiting = "waiting",
}

export enum SessionType {
  CALL = "CALL",
  CHAT = "CHAT",
  PHONE_CALL = "PHONE_CALL",
  VIDEO_CALL = "VIDEO_CALL",
}

export enum SortByEnum {
  date = "date",
  id = "id",
  rate = "rate",
  rating = "rating",
}

export enum UserLoginType {
  email = "email",
  facebook = "facebook",
  google = "google",
  vk = "vk",
}

export interface CalculateCashbackInput {
  paymentAmount: number;
}

export interface DateFilterS {
  earlierThan?: any | null;
  laterThan?: any | null;
}

export interface ExchangeRateInput {
  paymentProvider: PaymentProvider;
  curFrom: Currency;
  curTo: Currency;
}

export interface ExpertFilter {
  scopes?: number[] | null;
  onlineOnly?: boolean | null;
  skills?: number[] | null;
  gender?: Gender | null;
  maxRate?: number | null;
  sessionType?: SessionType[] | null;
  methods?: number[] | null;
  displayStatus?: ExpertDisplayStatus | null;
}

export interface GetPaymentFormDataInput {
  formType: PaymentFormType;
  expertID?: number | null;
  sessionType?: SessionType | null;
}

export interface GetScheduleFilter {
  DateFilter?: DateFilterS | null;
  ExpertFilter: any[];
}

export interface NewPaymentIn {
  amount: number;
  paymentProvider: PaymentProvider;
  isNeedToSaveCard: boolean;
  link?: string | null;
  location: PaymentLocation;
  relatedID: number;
}

export interface ProductHistoryFilter {
  id?: number | null;
  productID?: number | null;
  paymentID?: number | null;
  expertID?: number | null;
  sessionID?: number | null;
  userID?: number | null;
  dateFrom?: any | null;
  dateTo?: any | null;
}

export interface SaveRefererInput {
  referer: string;
  eventType: string;
  sessionID?: number | null;
  paymentID?: number | null;
}

export interface SessionFilter {
  minDurationInMinutes?: number | null;
  maxDurationInMinutes?: number | null;
}

export interface SortParameters {
  sortBy: SortByEnum;
  order: OrderEnum;
}

export interface UseProduct {
  productID: number;
  cardID: number;
  sessionID: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
